@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif !important;
}

body {
  font-family: 'Inter', sans-serif !important;
}
.ant-card-new {
  box-shadow: 0.6px 2.8px 0.6px rgba(0, 0, 0, 0.008),
    1.3px 6.7px 1.3px rgba(0, 0, 0, 0.012),
    2.5px 12.5px 2.5px rgba(0, 0, 0, 0.015),
    4.5px 22.3px 4.5px rgba(0, 0, 0, 0.018),
    8.4px 41.8px 8.4px rgba(0, 0, 0, 0.022), 20px 100px 20px rgba(0, 0, 0, 0.03) !important;
}
.bg-auth {
  background-color: #f4f6f8;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.max-w-xl {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.gradient-bg {
  background: linear-gradient(to bottom, #ff8036, #ffb58b);
  height: calc(100vh - 150px);
  border-radius: 20px;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  z-index: 1;
}
.auth-bg {
  position: absolute;
  background-image: url('./assets/auth-rm.png');
  height: 400px;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  background-size: cover;
}

.font-thin {
  font-weight: 100 !important;
}
.font-normal {
  font-weight: 400 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}

.text-xs {
  font-size: 0.75rem !important;
} /* Extra Small */
.text-sm {
  font-size: 0.875rem !important;
} /* Small */
.text-base {
  font-size: 1rem !important;
} /* Base */
.text-lg {
  font-size: 1.125rem !important;
} /* Large */
.text-xl {
  font-size: 1.25rem !important;
} /* Extra Large */
.text-2xl {
  font-size: 1.5rem !important;
} /* 2 Extra Large */
.text-3xl {
  font-size: 1.875rem !important;
} /* 3 Extra Large */
.text-4xl {
  font-size: 2.25rem !important;
} /* 4 Extra Large */
.text-5xl {
  font-size: 3rem !important;
} /* 5 Extra Large */
.text-6xl {
  font-size: 4rem !important;
} /* 6 Extra Large */

.my-0 {
  margin-block: 0 !important;
}
.w-full {
  width: 100% !important;
}

.m-1 {
  margin: 0.25rem !important;
} /* Margin 1 for all sides */
.mt-1 {
  margin-top: 0.25rem !important;
} /* Margin top 1 */
.mr-1 {
  margin-right: 0.25rem !important;
} /* Margin right 1 */
.mb-1 {
  margin-bottom: 0.25rem !important;
} /* Margin bottom 1 */
.ml-1 {
  margin-left: 0.25rem !important;
} /* Margin left 1 */

.m-2 {
  margin: 0.5rem !important;
} /* Margin 2 for all sides */
.mt-2 {
  margin-top: 0.5rem !important;
} /* Margin top 2 */
.mr-2 {
  margin-right: 0.5rem !important;
} /* Margin right 2 */
.mb-2 {
  margin-bottom: 0.5rem !important;
} /* Margin bottom 2 */
.ml-2 {
  margin-left: 0.5rem !important;
} /* Margin left 2 */

.m-3 {
  margin: 0.75rem !important;
} /* Margin 3 for all sides */
.mt-3 {
  margin-top: 0.75rem !important;
} /* Margin top 3 */
.mr-3 {
  margin-right: 0.75rem !important;
} /* Margin right 3 */
.mb-3 {
  margin-bottom: 0.75rem !important;
} /* Margin bottom 3 */
.ml-3 {
  margin-left: 0.75rem !important;
} /* Margin left 3 */

.m-4 {
  margin: 1rem !important;
} /* Margin 4 for all sides */
.mt-4 {
  margin-top: 1rem !important;
} /* Margin top 4 */
.mr-4 {
  margin-right: 1rem !important;
} /* Margin right 4 */
.mb-4 {
  margin-bottom: 1rem !important;
} /* Margin bottom 4 */
.ml-4 {
  margin-left: 1rem !important;
} /* Margin left 4 */

.m-5 {
  margin: 1.25rem !important;
} /* Margin 5 for all sides */
.mt-5 {
  margin-top: 1.25rem !important;
} /* Margin top 5 */
.mr-5 {
  margin-right: 1.25rem !important;
} /* Margin right 5 */
.mb-5 {
  margin-bottom: 1.25rem !important;
} /* Margin bottom 5 */
.ml-5 {
  margin-left: 1.25rem !important;
} /* Margin left 5 */

.p-1 {
  padding: 0.25rem !important;
} /* Padding 1 for all sides */
.pt-1 {
  padding-top: 0.25rem !important;
} /* Padding top 1 */
.pr-1 {
  padding-right: 0.25rem !important;
} /* Padding right 1 */
.pb-1 {
  padding-bottom: 0.25rem !important;
} /* Padding bottom 1 */
.pl-1 {
  padding-left: 0.25rem !important;
} /* Padding left 1 */

.p-2 {
  padding: 0.5rem !important;
} /* Padding 2 for all sides */
.pt-2 {
  padding-top: 0.5rem !important;
} /* Padding top 2 */
.pr-2 {
  padding-right: 0.5rem !important;
} /* Padding right 2 */
.pb-2 {
  padding-bottom: 0.5rem !important;
} /* Padding bottom 2 */
.pl-2 {
  padding-left: 0.5rem !important;
} /* Padding left 2 */

.p-3 {
  padding: 0.75rem !important;
} /* Padding 3 for all sides */
.pt-3 {
  padding-top: 0.75rem !important;
} /* Padding top 3 */
.pr-3 {
  padding-right: 0.75rem !important;
} /* Padding right 3 */
.pb-3 {
  padding-bottom: 0.75rem !important;
} /* Padding bottom 3 */
.pl-3 {
  padding-left: 0.75rem !important;
} /* Padding left 3 */

.p-4 {
  padding: 1rem !important;
} /* Padding 4 for all sides */
.pt-4 {
  padding-top: 1rem !important;
} /* Padding top 4 */
.pr-4 {
  padding-right: 1rem !important;
} /* Padding right 4 */
.pb-4 {
  padding-bottom: 1rem !important;
} /* Padding bottom 4 */
.pl-4 {
  padding-left: 1rem !important;
} /* Padding left 4 */

.p-5 {
  padding: 1.25rem !important;
} /* Padding 5 for all sides */
.pt-5 {
  padding-top: 1.25rem !important;
} /* Padding top 5 */
.pr-5 {
  padding-right: 1.25rem !important;
} /* Padding right 5 */
.pb-5 {
  padding-bottom: 1.25rem !important;
} /* Padding bottom 5 */
.pl-5 {
  padding-left: 1.25rem !important;
} /* Padding left 5 */

.min-h-100 {
  min-height: 100vh;
}
.navbar {
  background-color: #ffffff;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-brand {
  font-weight: bold;
}

.navbar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navbar-menu li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  font-size: 15px;
}

.navbar-menu li:hover {
  background-color: #f3f3f3;
}
.navbar-menu li svg {
  margin-top: -2px;
}

.navbar-menu li.active,
.navbar-menu li.active svg {
  background-color: #3b5895;
  color: white;
}

.navbar-menu li.active svg {
  fill: white; /* Change the icon color to white */
}

.navbar-menu li span.menu-label {
  margin-left: 10px;
}
.navbar-brand {
  display: none;
}

@media (max-width: 1078px) {
  .navbar-brand {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
  }
  .navbar-menu {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
    transition: max-height 0.3s;
    overflow: hidden;
    max-height: 0;
    z-index: 99;
  }

  .navbar-menu.open {
    max-height: 300px;
  }

  .navbar-menu li {
    width: 100%;
  }
}
.divider-contact-detail {
  height: 1.9em !important;
  border-color: #ccc !important;
  margin-inline: 0 !important;
}
.w-6 {
  width: 20px;
}
.h-6 {
  height: 20px;
}
.icon-button {
  padding: 0 10px !important;
  display: flex !important;
  align-items: center;
  gap: 5px;
  min-width: 100px;
}

.icon-button span:nth-child(2) {
  margin-top: 2.5px;
}

.card-section {
  margin-bottom: 5px;
}

.section-title {
  margin: 0;
  font-size: 1.2rem;
}

.section-line {
  border: 0;
  height: 1px;
  background-color: #ddd;
  margin: 3px 0;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.group-header {
  font-weight: 600;
  letter-spacing: 0.2px;
  border-radius: 5px;
  background-color: #f4f4f4;
  padding: 10px;
}

.ant-layout {
  background: #f0f2f5;
}

.ant-card {
  margin-bottom: 16px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}

.ant-descriptions-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.ant-progress {
  width: 100%;
}

.custom_table thead tr {
  height: 50px !important;
  min-height: 50px;
  max-height: 50px;
}

.status-pending {
  background-color: #f5e8e0;
  max-width: 121px;
  width: 100%;
  border-radius: 3px;
  color: #792c00;
  text-align: center;
  margin-top: 2.5px;
}

.status-approved,
.status-finalized {
  background-color: #b4f7b4;
  max-width: 121px;
  width: 100%;
  border-radius: 3px;
  color: #1f7326;
  text-align: center;
  margin-top: 2.5px;
}

.status-rejected {
  background-color: #f7bbbb;
  max-width: 121px;
  width: 100%;
  border-radius: 3px;
  color: #732626;
  text-align: center;
  margin-top: 2.5px;
}

.group-tag {
  background-color: #f5e8e0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  color: #732626;
  text-align: center;
  margin-top: 2.5px;
  max-width: 80px;
}
.status-draft {
  background-color: #e0e0f5;
  max-width: 121px;
  width: 100%;
  border-radius: 3px;
  color: #003279;
  text-align: center;
  margin-top: 2.5px;
}

.status-na {
  background-color: #f0f0f0;
  max-width: 121px;
  width: 100%;
  border-radius: 3px;
  color: #555555;
  text-align: center;
  margin-top: 2.5px;
}

.percentage-label {
  font-size: 16px;
  font-weight: 500;
  color: #51ab0b;
  margin-top: 5px;
}

.styled-select {
  display: inline-block;
  padding: 4px 13px; /* Adjust padding as needed */
  border-radius: 10px; /* Rounded corners */
  color: #8b4513; /* Text color */
  font-size: 12px; /* Font size */
  font-weight: bold; /* Font weight */
  border: none; /* Remove default border */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center; /* Center text */
  margin: 5px; /* Space between tags */
}

/* Default option styling */
.status-default {
  color: #888;
}

/* New status styling */
.status-new {
  background-color: #f0f8ff; /* Light blue */
}

/* Hot status styling */
.status-hot {
  background-color: #ffcccb; /* Light red */
}

/* Warm status styling */
.status-warm {
  background-color: #fffacd; /* Light yellow */
}

/* Cold status styling */
.status-cold {
  background-color: #e0ffff; /* Light cyan */
}
.styled-select:hover {
  opacity: 0.8; /* Slightly transparent on hover */
}

.status-invoice-tag {
  display: inline-block;
  padding: 4px 25px;
  border-radius: 8px;
  font-size: 11.5px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-right: 8px;
}

.status-invoice-pending {
  background-color: #ffe082;
  color: #333;
}

.status-invoice-paid {
  background-color: #5af25a;
  color: #393737;
}

.status-invoice-overdue {
  background-color: #ef5350;
  color: #fff;
}

.status-invoice-overpaid {
  background-color: #4da6ff;
  color: #fff;
}

.status-invoice-partially-paid {
  background-color: #9c4dcc;
  color: #fff;
}

.react-tel-input .form-control {
  width: 100% !important;
}
.fw-400 {
  font-weight: 400 !important;
}

/* @media (min-width: 1440px) {
  .responsive-width {
    width: 7vw !important;
  }
}

@media (max-width: 1439px) {
  .responsive-width {
    width: 10vw !important;
  }
} */
